footer {
	background-color: var(--footer-bg-color);
	display: flex !important;
	justify-content: center;
	padding: 70px 0 !important;
	.mobile {
		display: none !important;
	}
	.container {
		width: 90%;
		max-width: var(--max-width);
		display: flex;
		h6 {
			display: flex;
			justify-content: center;
			gap: 24px;
			align-items: center;
			flex: 1;
			flex-wrap: wrap;
		}
		.eai {
			width: 386px;
			font-family: var(--polysans);
			font-weight: 900;
			color: var(--main-bg-color);
			margin: 0 230px 0 40px;
			big {
				font-size: 24px;
				display: block;
				margin-bottom: 26px;
			}
			a {
				color: var(--main-bg-color);
				display: inline-flex;
				align-items: center;
				gap: 10px;
				justify-content: center;
				font-size: 16px;
				padding: 11px 30px;
				border-radius: 100px;
				background: white;
				/* border: 1px solid #132a3f; */
				box-shadow: 0 2px 0 0 #e8e8e8;
				margin-top: 2px;
			}
		}
		.acompanhe {
			p {
				font-family: var(--polysans);
				color: var(--main-bg-color);
				font-weight: 900;
				font-size: 24px;
				margin-bottom: 20px;
			}
			ul {
				display: flex;
				align-items: center;
				gap: 13px;
				li a {
					width: 40px;
					height: 40px;
					background-color: white;
					box-shadow: 0 2px 0 0 #e8e8e8;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 100px;
					svg * {
						fill: var(--main-bg-color);
					}
				}
			}
		}
	}
}
