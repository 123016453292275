header.mainHeader {
	background: url("../assets/images/banners/banner-interna-sportv.webp") no-repeat center;
	background-size: cover;
	padding: 56px 0 86px !important;
	display: flex !important;
	justify-content: center;
	.mobile {
		display: none !important;
	}
	.container {
		width: 90%;
		max-width: var(--max-width);
		.topLink {
			margin: 0 0 52px;
			display: flex;
			justify-content: space-between;
		}
		span.moperadoras {
			&.hidemob {
				display: block;
			}
			&.hidedesk {
				display: none;
			}
			span {
				color: white;
				cursor: pointer;
				&:hover {
					text-decoration: underline;
				}
			}
		}
		h2 {
			font-weight: 900;
			font-size: 60px;
			color: white;
			font-family: var(--galeanacomp);
			font-weight: 900;
			font-style: italic;
			text-transform: uppercase;
		}
	}
}
.modalOperadora {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	.overlay {
		background: rgba(2, 12, 28, 0.93);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.modalBody {
		background-color: white;
		width: 95%;
		max-width: 720px;
		max-height: 84vh;
		overflow-y: auto;
		position: relative;
		z-index: 2;
		padding: 15px;
		border-radius: 15px;
		img {
			width: 100%;
			border-radius: 15px;
		}
		.closeModal {
			position: absolute;
			top: 20px;
			right: 20px;
			border: none;
			width: 50px;
			background: none;
			cursor: pointer;
			img {
				width: 50px;
			}
		}
	}

	&.nonactive {
		display: none;
	}
}
