/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a {
	text-decoration: none;
}

html {
	background-color: var(--main-bg-color);
	font-family: var(--polysans-wide);
}

body.stopScroll {
	overflow: hidden;
}

button {
	cursor: pointer;
}

header.homeHeader {
	min-height: 100vh;
	background: url("../assets/images/banners/home-sportv.webp") no-repeat top center;
	background-size: cover;
	display: flex;
	justify-content: center;
	.container {
		padding: 50px 0 120px;
		width: 90%;
		max-width: var(--max-width);
		color: white;
		h2 {
			font-size: 85px;
			font-weight: 900;
			line-height: 1.2em;
			margin: 35px 0 25px;
			max-width: 700px;
			font-family: var(--galeanacond);
			text-transform: uppercase;
		}
		p {
			max-width: 500px;
			font-size: 21px;
			line-height: 1.2em;
			margin-bottom: 30px;
			b {
				font-weight: 900;
			}
		}
		a.buttonCalendar {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			gap: 13px;
			padding: 20px 40px;
			color: var(--button-font-color);
			background: var(--home-button-gradient);
			border-radius: 100px;
			svg * {
				fill: var(--button-font-color);
			}
		}
	}
}
