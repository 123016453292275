@font-face {
	font-family: "PolySansWide";
	font-weight: 400;
	font-style: normal;
	src: url("../assets/fonts/polysans_wide/PolySansNeutralWide.ttf");
}

@font-face {
	font-family: "PolySansWide";
	font-weight: 900;
	font-style: normal;
	src: url("../assets/fonts/polysans_wide/PolySansBulkyWide.ttf");
}
@font-face {
	font-family: "PolySans";
	font-weight: 900;
	font-style: normal;
	src: url("../assets/fonts/polysans/PolySansBulky.ttf");
}
@font-face {
	font-family: "GaleanaCompressed";
	font-weight: 900;
	font-style: italic;
	src: url("../assets/fonts/galeana/GaleanaCompressedBlackIt.ttf");
}
@font-face {
	font-family: "GaleanaCompressed";
	font-weight: 700;
	font-style: normal;
	src: url("../assets/fonts/galeana/GaleanaCompressedBold.ttf");
}
@font-face {
	font-family: "GaleanaCondensed";
	font-weight: 900;
	font-style: italic;
	src: url("../assets/fonts/galeana/galeanacondensed-blackit-webfont.woff");
}
