.thanksMain {
	.container {
		display: flex;
		flex-direction: column;
		justify-content: start;
		align-items: center;
		flex-wrap: wrap;
		color: white;
		padding: 100px 0 50px;
		h2 {
			font-size: 40px;
			font-weight: 900;
			width: 100%;
			text-align: center;
		}
		.cardSport {
			background-color: var(--sports-icon-carrousel-bg-color);
			padding: 44px 100px;
			margin: 75px 0 25px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			border-radius: 25px;
			gap: 15px;
			span {
				font-size: 32px;
			}
			img {
				height: 163px;
			}
			.greenCheck {
				position: absolute;
				top: -15px;
				right: 43px;
			}
		}

		.infos {
			margin: 0px 0 95px;
			text-align: center;
			font-size: 22px;
			big,
			span {
				display: block;
			}
			big {
				margin-bottom: 6px;
			}
			span {
				text-transform: capitalize;
			}
		}
		a.backlink {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			gap: 13px;
			padding: 13px 20px;
			color: var(--button-font-color);
			background: var(--home-button-gradient);
			border-radius: 100px;
			border: none;
			font-size: 24px;
			img {
				transform: rotate(180deg);
			}
		}
		.addToCalendarButtons {
			display: flex;
			justify-content: center;
			gap: 20px;
			margin-bottom: 100px;
			a,
			button {
				display: flex;
				border: none;
				align-items: center;
				gap: 10px;
				padding: 10px 20px;
				border-radius: 100px;
				font-weight: 900;
				font-size: 14px;
				font-family: var(--polysans-wide);
				&.google {
					background: white;
					color: #4285f4;
				}
				&.apple {
					background: black;
					color: white;
					outline: 1px solid white;
					img {
						filter: brightness(0) invert(1);
					}
				}
				&.outlook {
					background: white;
					color: #034fa0;
				}
				img {
					height: 35px;
				}
			}
		}
	}
	.heroImg {
		display: block;
		width: 100%;
	}
	.mobile {
		display: none;
	}
}
