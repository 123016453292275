main.mainBody {
	.container {
		width: 90%;
		margin: 0 auto;
		max-width: var(--max-width);
		padding: 47px 0 0;

		&.scrollx {
			padding: 0;
			overflow-x: visible;
		}
		h1 {
			margin: 0 0 52px;
		}
		h2 {
			font-weight: 900;
			font-size: 60px;
			color: white;
		}
		h3 {
			color: white;
			font-size: 40px;
			font-weight: 900;
			margin-bottom: 3px;

			font-family: var(--galeanacomp);
			text-transform: uppercase;
		}
	}
	.centerLoading {
		width: 100%;
		text-align: center;
		padding: 10px;
		img {
			width: 60px;
		}
	}

	.arrowsWrapper {
		display: flex;
		justify-content: end;
		gap: 8px;
		margin-right: calc(calc(100vw - 1290px) / 2);
		margin-bottom: 20px;
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 45px;
			height: 45px;
			color: var(--button-font-color);
			background: var(--home-button-gradient);
			border-radius: 100px;
			border: none;
			font-size: 14px;
			&.left {
				img {
					transform: rotate(-180deg);
				}
			}
			&:disabled {
				opacity: 0.4;
				filter: grayscale(1);
				cursor: default;
			}
		}
	}
	ul.carrosselModalidades::-webkit-scrollbar {
		display: none;
	}
	ul.carrosselModalidades {
		display: flex;
		flex-wrap: nowrap;
		overflow-x: auto;
		width: 100%;
		height: 230px;
		gap: 33px;
		-ms-overflow-style: none;

		li {
			cursor: pointer;
			color: white;
			&:first-child {
				margin-left: calc(calc(100vw - 1290px) / 2);
			}
			&:last-child {
				margin-right: calc(calc(100vw - 1290px) / 2);
			}
			span {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 127px;
				height: 125px;
				border-radius: 23px;
				background-color: var(--sports-icon-carrousel-bg-color);
				transition: 0.3s linear;
				img {
					width: 75px;
				}
			}
			small {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				font-size: 19px;
				padding-top: 20px;
				font-family: var(--galeanacomp);
				text-transform: uppercase;
				font-weight: 700;
			}
			&:hover,
			&.active {
				span {
					background-color: var(--sports-icon-carrousel-hover-bg-color);
				}
				small {
					font-weight: 900;
					&:after {
						content: "";
						display: block;
						width: 45px;
						height: 2px;
						background-color: var(--accent-red-color);
						border-radius: 15px;
						margin-top: 16px;
					}
				}
			}
		}
	}
	.flexTopSchedule {
		color: white;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-bottom: 55px;
		h4 {
			font-size: 32px;
			font-weight: 900;
		}
		input {
			margin-right: 12px;
		}

		label {
			display: flex;
			align-items: center;
			img {
				width: 20px;
				height: 20px;
				margin-right: 10px;
			}
		}
	}
	.schedule {
		background-color: white;
		border: 1px solid var(--button-font-color);
		border-radius: 40px;
		padding: 50px 0;
		margin-bottom: 100px;
		.topSchedule {
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid rgba(0, 0, 0, 0.13);
			padding: 0 var(--schedule-horizontal-padding);
		}
		.eventTypes,
		.monthTabs {
			display: flex;
			align-items: center;
		}
		.monthTabs {
			gap: 10px;
			> div {
				height: 60px;
				width: 130px;
				margin-bottom: -3px;
				button {
					font-family: var(--polysans-wide);
					background: none;
					font-size: 32px;
					color: var(--month-button-tab-color);
					display: flex;
					border: none;
					height: 60px;
					border-bottom: 2px solid transparent;
					transition: 0.3s linear;

					&:disabled {
						cursor: default !important;
						color: var(--month-button-tab-color) !important;
						border-bottom: 2px solid transparent !important;
						opacity: 0.5;
						font-weight: 400 !important;
					}
				}

				&.active,
				&:hover {
					button {
						color: var(--main-bg-color);
						font-weight: 900;
						border-bottom: 2px solid var(--accent-red-color);
					}
				}
			}
		}
		.eventTypes {
			gap: 25px;
			li {
				display: flex;
				flex-wrap: nowrap;
				align-items: center;
				justify-content: end;
				gap: 9px;
				height: 60px;
			}
		}
		.calendar {
			padding: 35px var(--schedule-horizontal-padding) 45px;
			display: flex;
			align-items: center;
			.addButton {
				button {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					gap: 13px;
					padding: 13px 20px;
					color: var(--button-font-color);
					background: var(--home-button-gradient);
					border-radius: 100px;
					border: none;
					font-size: 14px;
				}
			}
			.days {
				flex: 1;
				> ul {
					display: flex;
					gap: 25px;
					li {
						font-size: 36px;
						color: var(--month-button-tab-color);
						text-align: center;
						cursor: pointer;
						font-weight: 900;
						small {
							font-size: 19px;
							font-weight: 400;
							display: block;
							text-transform: capitalize;
						}
						&.active,
						&:hover {
							color: var(--accent-red-color);
							small {
								color: var(--main-bg-color);
							}
						}
						&.disabled {
							opacity: 0.4;
							&.nonactive,
							&.active,
							&.hover {
								cursor: default;
								color: var(--month-button-tab-color) !important;
								small {
									color: var(--month-button-tab-color) !important;
								}
							}
						}
					}
				}
			}
		}
		p.topObs {
			padding: 0 var(--schedule-horizontal-padding);
			color: var(--month-button-tab-color);
			font-size: 16px;
			&.error {
				margin-top: 20px;
				font-size: 18px;
				color: var(--main-bg-color);
			}
		}
		section.sport {
			&:not(:first-child) {
				header {
					margin-top: 40px;
				}
			}
			header {
				display: flex;
				align-items: center;
				height: 60px;
				background-color: var(--sport-schedule-header-bg-color);
				border-top: 1px solid var(--sport-schedule-header-border-color);
				border-bottom: 1px solid var(--sport-schedule-header-border-color);
				padding: 0 var(--schedule-horizontal-padding);
				margin-bottom: 40px;
				gap: 20px;
				img {
					filter: brightness(0);
					max-height: 45px;
				}
			}
			main {
				padding: 0 var(--schedule-horizontal-padding);
				display: flex;
				flex-wrap: wrap;
				gap: 23px 16px;
				.evento {
					width: calc(50% - 50px);
					display: flex;
					gap: 30px;
					align-items: center;
					padding: 20px;
					border-radius: 16px;
					background-color: rgba(255, 255, 255, 1);
					border: 1px solid rgba(33, 67, 138, 0.16);
					cursor: pointer;
					transition: 0.3s ease-in-out;
					box-shadow: 0 0 0 0 rgba(33, 67, 138, 0);
					position: relative;
					.evtIcon {
						position: absolute;
						top: 20px;
						right: 20px;
					}
					&:hover {
						background-color: rgba(237, 246, 249, 0.62);
						box-shadow: -3px 7px 29px 0 rgba(33, 67, 138, 0.23);
						border: 1px solid #7c235b;
						/* border: 1px solid var(--accent-red-color); */
					}
					.evtHora {
						font-size: 22px;
						font-weight: 900;
						color: var(--accent-red-color);
						img {
							filter: brightness(0);
							max-height: 5000px;
							margin: 0 auto 8px;
						}
					}
					.evtInfo {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						gap: 18px;
						.topInfo {
							display: flex;
							flex-wrap: wrap;
							align-items: center;
							max-width: 85%;
							gap: 10px;
							font-size: 16px;
							color: var(--button-font-color);
							span:nth-child(2) {
								display: flex;
								align-items: center;
							}
						}
						.teams {
							display: flex;
							justify-content: start;
							gap: 30px;
							align-items: center;
							.team {
								font-weight: 900;
								font-size: 22px;
								color: var(--main-bg-color);
							}
						}
						button {
							display: inline-block;
							width: 180px;
							padding: 13px 20px;
							color: var(--button-font-color);
							background: var(--home-button-gradient);
							border-radius: 100px;
							border: none;
							font-size: 14px;
						}
						.wrapper {
							display: flex;
							gap: 20px;
							flex-wrap: wrap;
							img.globoplay {
								width: 180px;
							}
						}
					}
				}
			}
		}
	}
	.medalBoard {
		background-color: white;
		padding: 96px 0;
		.container.thinner {
			width: 90%;
			margin: 0 auto;
			max-width: 800px;
			h5 {
				font-size: 32px;
				font-weight: 900;
				color: var(--main-bg-color);
				margin-bottom: 55px;
				text-align: center;
			}
			ol {
				li.header {
					background: none !important;
					padding: 0 34px !important;
					> ul > li {
						padding: 0 !important;
						background: none !important;
						font-size: 20px;
						font-weight: 900;
						color: var(--month-button-tab-color);
						padding: 30px 34px;
					}
				}
				li {
					ul {
						display: flex;
						justify-content: space-between;
						gap: 15px;
					}
				}
				.spot {
					width: 5%;
					text-align: center;
				}
				.country {
					flex: 1;
				}
				.medal {
					width: 10%;
					text-align: center;
				}

				li:not(.header) {
					padding: 30px 34px;
					margin-bottom: 33px;
					background-color: var(--sport-schedule-header-bg-color);
					border-radius: 16px;
					> ul > li {
						background: none;
						margin-bottom: 0;
						padding: 0;
						font-size: 22px;
						font-weight: 900;
						color: var(--main-bg-color);
					}
				}

				li.top {
					background: var(--home-button-gradient);
					box-shadow: -5px 23px 28px -8px rgba(33, 67, 138, 0.28);
				}

				li.brasil {
					background: linear-gradient(30deg, #e89b05, #1aa036);
					box-shadow: -5px 23px 28px -8px rgba(33, 67, 138, 0.28);
					li {
						color: white !important;
					}
				}
			}
		}
	}
	.heroImage {
		img {
			max-width: 100%;
			display: block;
		}
	}
}
.modalCadastro {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	.overlay {
		background: rgba(2, 12, 28, 0.93);
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	.modalBody {
		background-color: white;
		width: 95%;
		max-width: 720px;
		max-height: 84vh;
		overflow-y: auto;
		position: relative;
		z-index: 2;
		padding: 56px 51px;
		border-radius: 43px;
		big {
			color: var(--main-bg-color);
			font-weight: 900;
			font-size: 26px;
			display: block;
			text-align: center;
			max-width: 470px;
			margin: 0 auto 30px;
			b {
				color: var(--accent-red-color);
			}
		}
		span.heading {
			display: block;
			font-size: 14px;
			font-weight: 400;
			color: rgba(38, 53, 67, 0.78);
			padding: 0 0 12px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.17);
		}
		.sportInfo {
			margin: 30px 0 50px 34px;
			li {
				display: flex;
				align-items: center;
				justify-content: flex-start;
				gap: 16px;
				&:nth-child(2) {
					margin: 30px 0;
				}
			}
			.left {
				color: var(--main-bg-color);
				font-size: 19px;
				font-weight: 900;
				display: block;
				text-align: right;
				width: 150px;
			}
			.right {
				img {
					max-height: 30px;
					filter: brightness(0);
				}
				font-size: 16px;
				color: var(--form-sport-font-color);
			}
		}
		form {
			margin: 25px 0 0 77px;
			.formField {
				display: flex;
				align-items: center;
				gap: 18px;
				margin-bottom: 16px;
				label {
					color: var(--main-bg-color);
					font-size: 19px;
					display: block;
					text-align: right;
					width: 110px;
					font-weight: 900;
				}
				input[type="text"],
				input[type="email"],
				select {
					background: rgba(227, 228, 228, 0.62);
					font-size: 16px;
					padding: 19px 15px;
					color: var(--form-sport-font-color);
					border: 1px solid var(--month-button-tab-color);
					border-radius: 7px;
					width: 353px;
					&::placeholder {
						color: rgba(38, 53, 67, 0.78);
					}
					&:focus {
						background: white;
						box-shadow: -3px 6px 26px 0 rgba(23, 67, 138, 0.23);
					}
				}
				select {
					width: 385px;
				}
			}
			button {
				margin: 12px 0 0 128px;
				display: inline-flex;
				justify-content: center;
				align-items: center;
				gap: 13px;
				padding: 12px 25px;
				color: var(--button-font-color);
				background: var(--home-button-gradient);
				border-radius: 100px;
				border: none;
				font-size: 17px;
			}
		}
		.closeModal {
			position: absolute;
			top: 36px;
			right: 48px;
			border: none;
			width: 50px;
			background: none;
			img {
				width: 50px;
			}
		}
	}

	&.nonactive {
		display: none;
	}
}

.backToTopButton {
	position: fixed;
	bottom: 30px;
	right: 30px;
	width: 50px;
	height: 50px;
	button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 50px;
		height: 50px;
		color: var(--button-font-color);
		background: var(--home-button-gradient);
		border-radius: 100px;
		border: none;
		font-size: 14px;
		img {
			transform: rotate(-90deg);
		}
	}
}
