@media (min-width: 320px) and (max-width: 768px) {
	:root {
		--schedule-horizontal-padding: 15px;
	}
	.homeHeader {
		background: url("../assets/images/banners/splash-mobile.webp") no-repeat center !important;
		background-size: cover !important;
		text-align: center;
		.container {
			h2 {
				font-size: 36px !important;
				margin-bottom: 150px !important;
			}
			p {
				font-size: 15px !important;
				line-height: 1.3em !important;
			}
		}
	}
	header.mainHeader {
		background: url("../assets/images/banners/header_new.webp") no-repeat center;
		background-size: cover;
		padding: 16px 0 86px !important;
		display: flex !important;
		justify-content: center;
		.mobile {
			display: none !important;
		}
		.container {
			.topLink {
				justify-content: center;
				flex-wrap: wrap;
			}
			span.moperadoras {
				/* display: none; */
				width: 100%;
				margin-bottom: 18px;
				font-size: 14px;
				&.hidemob {
					display: none !important;
				}
				&.hidedesk {
					display: flex !important;
					justify-content: start;
					text-align: left;
					margin-bottom: 40px;
					span {
						text-decoration: underline !important;
					}
				}
			}
			text-align: center;
			h2 {
				font-size: 46px;
			}
		}
	}
	main.mainBody {
		h3 {
			margin-bottom: 30px !important;
		}
	}
	.arrowsWrapper {
		display: none !important;
	}
	ul.carrosselModalidades {
		li {
			cursor: pointer;
			&:first-child {
				margin-left: calc(10% / 2) !important;
			}
			&:last-child {
				margin-right: calc(10% / 2) !important;
			}
			small {
				font-size: 19px;
			}
		}
	}
	.schedule {
		padding: 30px 0 !important;
	}

	.flexTopSchedule {
		h4 {
			font-size: 24px !important;
			margin-bottom: 30px;
		}
	}
	.topSchedule {
		flex-wrap: wrap;
		.monthTabs {
			order: 2;
			> div {
				margin-top: 20px;
				height: 40px !important;
				width: 90px !important;
			}
			button {
				font-size: 24px !important;
				height: 40px !important;
			}
		}
		.eventTypes {
			width: 100% !important;
			justify-content: end;
			order: 1;
			font-size: 10px !important;
			img {
				width: 9px !important;
			}
			li {
				height: auto !important;
			}
		}
	}
	.calendar {
		flex-wrap: wrap;
		.addButton {
			margin-top: 8px;
		}
		.days ul::-webkit-scrollbar {
			display: none;
		}
		.days {
			width: 100% !important;
			ul {
				width: 100% !important;
				overflow-x: scroll;
			}
		}
	}
	.evento {
		width: 100% !important;
		flex-wrap: wrap;
		text-align: center;
		.topInfo {
			justify-content: center;
			font-size: 15px !important;
			max-width: 100% !important;
		}
		.teams {
			gap: 10px !important;
			justify-content: center !important;
			.team {
				font-size: 16px !important;
			}
		}
		.wrapper {
			justify-content: center !important;

			img {
				width: 50% !important;
			}
		}
		button {
			width: 100% !important;
		}
	}

	.medalBoard {
		.container.thinner {
			h5 {
				font-size: 28px !important;
			}
			ol {
				li.header {
					> ul > li {
						font-size: 16px !important;
					}
				}
				li {
					ul {
						gap: 15px;
					}
				}
				.spot {
					width: 5%;
				}
				.country {
					flex: 1;
				}
				.medal {
					width: 10%;
				}

				li:not(.header) {
					> ul > li {
						font-size: 16px !important;
					}
				}
			}
		}
	}

	.modalCadastro {
		.modalBody {
			width: 85% !important;
			max-width: 720px;
			max-height: 76vh;
			padding: 83px 25px !important;
			big {
				font-size: 24px !important;
			}
			.sportInfo {
				margin: 30px 0 50px 0 !important;
				gap: 10px !important;
				.left {
					font-size: 14px !important;
					width: 35% !important;
					text-align: left !important;
				}
				.right {
					font-size: 14px !important;
					flex: 1;
				}
			}
			form {
				margin: 25px 0 0 0px !important;
				.formField {
					margin-bottom: 16px;
					label {
						font-size: 14px !important;
						width: 40% !important;
						text-align: left !important;
					}
					input[type="text"],
					input[type="email"],
					select {
						font-size: 14px !important;
					}
					select {
						width: 114% !important;
					}
				}
				button {
					margin: 12px 0 0 28% !important;
				}
			}
			.closeModal {
				position: absolute;
				top: 36px;
				right: 48px;
				border: none;
				width: 50px;
				background: none;
				img {
					width: 50px;
				}
			}
		}

		&.nonactive {
			display: none;
		}
	}

	footer .container {
		flex-wrap: wrap;
		h6 {
			width: 100% !important;
			text-align: center;
		}
		.eai {
			width: 100% !important;
			margin: 36px 0 28px;
			text-align: center;
		}
		.acompanhe {
			text-align: center;
			width: 100% !important;
			ul {
				justify-content: center;
			}
		}
	}
	.thanksMain {
		.container {
			padding: 39px 8% 10px !important;
			h2 {
				font-size: 24px !important;
			}
			.cardSport {
				padding: 24px 15px !important;
				margin: 38px 0 45px !important;
				gap: 15px !important;
				max-width: 100%;
				span {
					font-size: 19px !important;
				}
				img {
					height: 75px !important;
					max-width: 75px !important;
				}
				.greenCheck {
					top: -15px;
					right: 43px;
				}
			}

			.infos {
				margin: 0px 0 45px !important;
				font-size: 19px !important;
			}
			a.backlink {
				font-size: 17px !important;
			}
			.addToCalendarButtons {
				flex-wrap: wrap;
				gap: 20px;
				margin-bottom: 80px !important;
				a {
					width: 100%;
					gap: 10px !important;
					padding: 10px 20px !important;
					border-radius: 100px;
					font-weight: 900;
					&.google {
						background: white;
						color: #4285f4;
					}
					&.apple {
						background: black;
						color: white;
						img {
							filter: brightness(0) invert(1);
						}
					}
					img {
						height: 35px;
					}
				}
			}
		}
		.mobile {
			display: block;
		}
		.desktop {
			display: none;
		}
	}
}
