:root {
	--max-width: 1290px;

	/* Colors */

	--main-bg-color: #001434;
	--footer-bg-color: #f6f3f3;
	--button-font-color: #001434;
	--home-button-gradient: linear-gradient(to right, #7fbbf3, #ece5d3);
	--sports-icon-carrousel-bg-color: #021739;
	--sports-icon-carrousel-hover-bg-color: #002868;
	--accent-red-color: #c8002d;
	--month-button-tab-color: #787d83;
	--form-sport-font-color: #21438a;

	--sport-schedule-header-border-color: #cedce8;
	--sport-schedule-header-bg-color: #edf6f9;

	/* FONTS */

	--polysans-wide: "PolySansWide", Arial, Helvetica, sans-serif;
	--polysans: "PolySans", Arial, Helvetica, sans-serif;
	--galeanacomp: "GaleanaCompressed", Arial, Helvetica, sans-serif;
	--galeanacond: "GaleanaCondensed", Arial, Helvetica, sans-serif;

	/* Screen Sizes */

	--small-mobile-width: 320px;
	--mobile-width: 768px;
	--tablet-width: 769px;
	--desktop-width: 1350px;
	--large-desktop-width: 1440px;

	/* MISC */

	--schedule-horizontal-padding: 53px;
}

/*
Media Queries
$smallMobile: "(max-width: var(--small-mobile-width))";
$mobile: "(min-width: var(--small-mobile-width)) and (max-width: var(--mobile-width))";
$tablet: "(min-width: var(--tablet-width)) and (max-width: calc(var(--desktop-width)-1px))";
$desktop: "(min-width: var(--desktop-width)) and (max-width: calc(var(--large-desktop-width - 1px)))";
$desktopLarge: "(min-width: var(--large-desktop-width))"; */
